import React, { useState, useMemo, useEffect, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import Input from "../../shared/Input/Input";
import FormItem from "../PageAddListing1/FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from "react-router-dom";
import { useAuth } from "components/AuthLogin/AuthContext";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import "react-quill/dist/quill.snow.css";
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css'; //

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

type QuillCodeBlock = {
    new (): {
        domNode: HTMLElement;
    };
    create(value: string): HTMLElement;
    formats(domNode: HTMLElement): string | undefined;
};
const CodeBlock = Quill.import('formats/code-block') as QuillCodeBlock;

class HighlightCodeBlock extends CodeBlock {
    static create(value: string) {
        const domNode = super.create(value);
        domNode.classList.add('hljs');
        return domNode;
    }

    static formats(domNode: HTMLElement) {
        const className = domNode.className;
        if (className) {
            const match = className.match(/language-(\w+)/);
            return match ? match[1] : 'plaintext';
        }
        return 'plaintext';
    }
}
Quill.register('formats/code-block', HighlightCodeBlock, true);

const BlogWrite: React.FC = () => {
    const navigate = useNavigate();
    const { getCookie } = useAuth();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [content, setContent] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [tagInput, setTagInput] = useState("");
    const [coverImage, setCoverImage] = useState<File | null>(null);
    const [editorHeight, setEditorHeight] = useState(300);

    const highlightCodeBlocks = useCallback(() => {
        const codeBlocks = document.querySelectorAll('pre.ql-syntax');
        codeBlocks.forEach((block) => {
            if (block instanceof HTMLElement) {
                if (block.dataset.highlighted === 'true') {
                    return;
                }

                const langClass = Array.from(block.classList).find(cls => cls.startsWith('language-'));
                const lang = langClass ? langClass.replace('language-', '') : 'plaintext';

                block.className = block.className.replace(/hljs\S*/g, '').trim();
                block.classList.add('hljs', `language-${lang}`);
                hljs.highlightElement(block);
                block.dataset.highlighted = 'true';
            }
        });
    }, []);

    useEffect(() => {
        highlightCodeBlocks();
    }, [content, highlightCodeBlocks]);

    const modules = useMemo(() => ({
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'direction': 'rtl' }],
            ["link", "image", "code-block"],
            [{ align: [] }, { color: [] }, { background: [] }],
        ],
        syntax: {
            highlight: (text: string) => hljs.highlightAuto(text).value,
        },
        imageActions: {
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
            sizing: {
                sizes: [
                    { name: 'small', value: '300' },
                    { name: 'medium', value: '600' },
                    { name: 'large', value: '900' },
                ]
            }
        },
        imageFormats: {},
    }), []);

    const formats = [
        "header", "bold", "italic", "underline", "strike", "blockquote",
        "list", "indent", "link", "image", "align", "float", "color", "background",
        'height', 'width', "code-block",

    ];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // 폼의 기본 제출 동작 방지

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('content', content);
        formData.append('tags', JSON.stringify(tags));
        if (coverImage) {
            formData.append('featuredImage', coverImage); // 실제 파일을 FormData에 추가
        }

        const userId = getCookie('userId');
        if (userId) {
            formData.append('userId', userId);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error:", errorData.message);
                console.error("Detailed error:", errorData.error);
                throw new Error("Network response was not ok");
            }

            //const responseData = await response.json();
            navigate('/blog');
        } catch (error) {
            console.error("Error:", error);
            // 에러 처리
        }
    };

    const handleContentChange = (value: string) => {
        setContent(value);
    };

    const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTagInput(e.target.value);
    };

    const handleAddTag = () => {
        if (tagInput.trim() !== "") {
            setTags([...tags, tagInput.trim()]);
            setTagInput(""); // 입력 필드 초기화
        }
    };

    const handleRemoveTag = (index: number) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const handleAddPresetTag = (presetTag: string) => {
        if (!tags.includes(presetTag)) {
            setTags([...tags, presetTag]);
        }
    };

    const handleCoverImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setCoverImage(file);
        }
    };

    const handleResize = (event: any, { size }: { size: { height: number } }) => {
        setEditorHeight(size.height);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`nc-PageAddListing1 px-4 max-w-7xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
            data-nc-id="PageAddListing1"
        >
            <div className="space-y-11">
                <div className="listingSection__wrap">
                    <h2 className="text-2xl font-semibold">Create a New Blog Post</h2>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-8"></div>
                    {/* FORM */}
                    <div className="space-y-8">
                        <FormItem
                            label="Cover Image"
                            desc="Upload a cover image for your blog post"
                        >
                            <div className="mt-5">
                                <div
                                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-neutral-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                        <div className="flex text-sm text-neutral-600 dark:text-neutral-300">
                                            <label
                                                htmlFor="cover-image-upload"
                                                className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    id="cover-image-upload"
                                                    name="cover-image-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={handleCoverImageChange}
                                                />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>
                                </div>
                                {coverImage && (
                                    <div className="mt-4">
                                        <img
                                            src={URL.createObjectURL(coverImage)}
                                            alt="Cover preview"
                                            className="w-full max-w-xs mx-auto"
                                        />
                                    </div>
                                )}
                            </div>
                        </FormItem>
                        <FormItem
                            label="Blog Title"
                            desc="Enter a catchy title for your blog post"
                        >
                            <Input
                                placeholder="Blog title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                                aria-required="true"
                            />
                        </FormItem>
                        <FormItem
                            label="Blog Description"
                            desc="Briefly describe the main points or topic of your blog post."
                        >
                            <Input
                                placeholder="Blog description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                aria-required="true"
                            />
                        </FormItem>
                        <FormItem
                            label="Blog Tags"
                            desc="Add relevant tags to categorize your blog post"
                        >
                            <div className="flow-root">
                                <div className="flex flex-wrap -m-1">
                                    {tags.map((tag, index) => (
                                        <span key={index}
                                              className="m-1 bg-neutral-200 dark:bg-neutral-700 px-3 py-1 rounded-full text-sm flex items-center">
                                            #{tag}
                                            <i
                                                className="ml-2 text-xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
                                                onClick={() => handleRemoveTag(index)}
                                            ></i>
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-wrap mt-4">
                                {["Elasticsearch", "Linux", "Laravel"].map((presetTag, index) => (
                                    <button
                                        type="button"
                                        key={index}
                                        className="m-1 bg-neutral-300 dark:bg-neutral-600 px-3 py-1 rounded-full text-sm hover:bg-neutral-400 dark:hover:bg-neutral-500"
                                        onClick={() => handleAddPresetTag(presetTag)}
                                    >
                                        {presetTag}
                                    </button>
                                ))}
                            </div>
                            <div
                                className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5 pt-3">
                                <Input
                                    className="!h-full"
                                    placeholder="Blog Tag"
                                    value={tagInput}
                                    onChange={handleTagInputChange}
                                />
                                <ButtonSecondary className="flex-shrink-0" onClick={handleAddTag}>
                                    <i className="text-xl las la-plus"></i>
                                    <span className="ml-3">Add tag</span>
                                </ButtonSecondary>
                            </div>
                        </FormItem>
                        <FormItem
                            label="Blog Content"
                            desc="Write your blog content using the rich text editor below"
                        >
                            <ResizableBox
                                width={Infinity}
                                height={editorHeight}
                                minConstraints={[Infinity, 200]}
                                onResize={handleResize}
                                resizeHandles={['se']}
                            >
                                <ReactQuill
                                    theme="snow"
                                    value={content}
                                    onChange={handleContentChange}
                                    modules={modules}
                                    formats={formats}
                                    style={{height: editorHeight - 42, marginBottom: "50px"}}
                                />
                            </ResizableBox>
                        </FormItem>
                    </div>
                </div>

                <div className="flex justify-end space-x-5">
                    <ButtonSecondary href="/blog">Cancel</ButtonSecondary>
                    <ButtonPrimary type="submit">
                        Publish
                    </ButtonPrimary>
                </div>
            </div>
        </form>
    );
};

export default BlogWrite;