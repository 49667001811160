import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "components/AuthLogin/AuthContext";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageLogin from "components/AuthLogin/AuthLogin";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import BlogWrite from "containers/BlogPage/BlogWrite";
import BlogModify from "containers/BlogPage/BlogModify";
import BlogList from "containers/BlogPage/BlogList/BlogList";
import BlogFooterNav from "containers/BlogPage/BlogHeader/BlogFooterNav";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import ProtectedRoute from "routers/ProtectedRoute";

export const pages: Page[] = [
  { path: "/", exact: true, component: BlogPage },
  { path: "/#", exact: true, component: BlogPage },  //

  { path: "/account", component: AccountPage, requireAuth: true },

  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/blog-write", component: BlogWrite, requireAuth: true },
  { path: "/blog-modify", component: BlogModify },
  { path: "/blog-list", component: BlogList },

  { path: "/login", component: PageLogin },
  //
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
      <BrowserRouter>
        <ScrollToTop />
        <AuthProvider>
          <SiteHeader />
          <Routes>
            {pages.map(({ component, path, requireAuth }) => {
              const Component = component;
              return (
                  <Route
                      key={path}
                      path={path}
                      element={
                        requireAuth ? (
                            <ProtectedRoute>
                              <Component />
                            </ProtectedRoute>
                        ) : (
                            <Component />
                        )
                      }
                  />
              );
            })}
            <Route path="*" element={<Page404 />} />
          </Routes>

          {WIN_WIDTH < 768 && <BlogFooterNav />}
          <Footer />
        </AuthProvider>
      </BrowserRouter>
  );

 /* return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthProvider>
        <SiteHeader />
        <Routes>
          {pages.map(({ component, path, requireAuth }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
          <Route element={<Page404 />} />
        </Routes>

        {WIN_WIDTH < 768 && <FooterNav />}
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  );*/
};

export default MyRoutes;
